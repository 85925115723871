<template>
  <div style="height: 100%">
    <div style="width: 100%">
      <div style="width: 100%;font-size: 19px;font-weight: 600;color: rgb(19,135,220)">
        <a-icon type="area-chart" style="font-size: 22px"/>
        网站今日数据
      </div>

      <div
          style="margin-top: 15px;display: flex;flex-wrap: wrap;align-items: center;height: 180px;justify-content: space-evenly">

        <a-tag color="orange"
               style="width: 20%;height: 100%;display: flex;border-radius: 15px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);background-color: white">
          <div style="width: 40%;display:flex;justify-content: center;align-items: center">
            <a-icon type="user" style="font-size: 60px;"/>
          </div>
          <div style="width: 60%">
            <div
                style="width: 100%;height:40%;font-size: 20px;display: flex;align-items: center;font-weight: bold">
              用户数量
            </div>
            <div
                style="width: 100%;height: 50%;font-size: 30px;display: flex;justify-content: center;align-items: center">
              {{ today_info.userCount }}
            </div>
          </div>
        </a-tag>

        <a-tag color="cyan"
               style="width: 20%;height: 100%;display: flex;border-radius: 15px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);background-color: white">
          <div style="width: 40%;display:flex;justify-content: center;align-items: center">
            <a-icon type="container" style="font-size: 60px;"/>
          </div>
          <div style="width: 60%">
            <div
                style="width: 100%;height:40%;font-size: 20px;display: flex;align-items: center;font-weight: bold">
              订单数
            </div>
            <div
                style="width: 100%;height: 50%;font-size: 30px;display: flex;justify-content: center;align-items: center">
              {{ today_info.orderCount }}
            </div>
          </div>
        </a-tag>

        <a-tag color="green"
               style="width: 20%;height: 100%;display: flex;border-radius: 15px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);background-color: white">
          <div style="width: 40%;display:flex;justify-content: center;align-items: center">
            <a-icon type="container" style="font-size: 60px;"/>
          </div>
          <div style="width: 60%">
            <div
                style="width: 100%;height:40%;font-size: 20px;display: flex;align-items: center;font-weight: bold">
              免费作图次数
            </div>
            <div
                style="width: 100%;height: 50%;font-size: 30px;display: flex;justify-content: center;align-items: center">
              {{ today_info.freeImgCount }}
            </div>
          </div>
        </a-tag>


        <a-tag color="purple"
               style="width: 20%;height: 100%;display: flex;border-radius: 15px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);background-color: white">
          <div style="width: 40%;display:flex;justify-content: center;align-items: center">
            <a-icon type="container" style="font-size: 60px;"/>
          </div>
          <div style="width: 60%">
            <div
                style="width: 100%;height:40%;font-size: 20px;display: flex;align-items: center;font-weight: bold">
              付费作图次数
            </div>
            <div
                style="width: 100%;height: 50%;font-size: 30px;display: flex;justify-content: center;align-items: center">
              {{ today_info.isPayImgCount }}
            </div>
          </div>
        </a-tag>


      </div>
    </div>

    <div style="width: 100%;font-size: 19px;font-weight: 600;color: rgb(19,135,220);margin-top: 10px;">
      <a-icon type="frown" style="font-size: 22px"/>
      网站信息
    </div>
    <div style="width: 100%;margin-top: 15px;display: flex;justify-content: space-evenly;align-items: center">


      <a-tag color="red" @click="webInfo_setting=true"
             style="width: 20%;height: 100px;display: flex;border-radius: 15px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);background-color: white">
        <div style="width: 40%;display:flex;justify-content: center;align-items: center">
          <a-icon type="edit" style="font-size: 60px;"/>
        </div>
        <div style="width: 100%;">

          <div
              style="width: 100%;height: 100%;font-size: 25px;display: flex;justify-content: center;align-items: center">
            修改网站配置
          </div>
        </div>
      </a-tag>
      <a-tag color="red"
             style="width: 20%;opacity: 0;height: 100px;display: flex;border-radius: 15px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);background-color: white">
      </a-tag>
      <a-tag color="red"
             style="width: 20%;opacity: 0;height: 100px;display: flex;border-radius: 15px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);background-color: white">
      </a-tag>
      <a-tag color="red"
             style="width: 20%;opacity: 0;height: 100px;display: flex;border-radius: 15px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);background-color: white">
      </a-tag>


    </div>


    <div style="width: 100%;font-size: 19px;font-weight: 600;color: rgb(19,135,220);margin-top: 3rem;">
      <a-icon type="meh" style="font-size: 22px"/>
      顶部导航栏
    </div>


    <div style="display: flex;flex-wrap: wrap;align-items: center;">


      <div v-for="(item,i) in website_info.headerLinks" :key="item.id" style="width: 10%;margin: 0 5px"
           @click="tempObject={webname: item.name, weburl: item.url, index: item.index, position: item.position}">
        <a-popover v-model="header_add_show_edit[i]" trigger="click" :destroyTooltipOnHide="true">
          <div slot="content">
            <div slot="content">
              <div style="margin:8px 0">
                <a-input addon-before="名称" v-model="tempObject.webname" placeholder="输入名称"/>
              </div>

              <div style="margin:8px 0">
                <a-input addon-before="地址" v-model="tempObject.weburl" placeholder="输入完整的https地址"/>
              </div>

              <div style="margin:8px 0">
                <a-input addon-before="权重" v-model="tempObject.index" placeholder="0-100"/>
              </div>

              <div style="margin:8px 0">
                <a-input addon-before="位置" v-model="tempObject.position" placeholder="0为底部 1为顶部"/>
              </div>

              <div style="margin:8px 0;display: flex;justify-content: space-between">
                <a-button type="danger" ghost style="width: 30%" @click="$set(header_add_show_edit,i,false)">取消
                </a-button>
                <a-button type="danger" ghost style="width: 30%" @click="deleteItem(item.id,i)">删除</a-button>
                <a-button type="primary" ghost style="width: 30%" @click="editITem(item.id,i)">修改</a-button>
              </div>
            </div>
          </div>
          <a-tag color="purple"
                 style="width: 100%;height:40px;margin-top: 10px;line-height: 40px;display: flex;border-radius: 15px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);background-color: white">
            <div style="font-size: 14px;font-weight: 600;text-align: center;width: 100%">
              {{ item.name }}
            </div>
          </a-tag>
        </a-popover>

      </div>


      <a-popover v-model="header_add_show" trigger="click" :destroyTooltipOnHide="true">
        <div slot="content">
          <div style="margin:8px 0">
            <a-input addon-before="名称" v-model="tempObject.webname" placeholder="输入名称"/>
          </div>

          <div style="margin:8px 0">
            <a-input addon-before="地址" v-model="tempObject.weburl" placeholder="输入完整的https地址"/>
          </div>

          <div style="margin:8px 0">
            <a-input addon-before="权重" v-model="tempObject.index" placeholder="0-100"/>
          </div>

          <div style="margin:8px 0">
            <a-input addon-before="位置" v-model="tempObject.position" placeholder="0为底部 1为顶部"/>
          </div>

          <div style="margin:8px 0;display: flex;justify-content: space-between">
            <a-button type="danger" ghost style="width: 40%" @click="header_add_show=false">取消</a-button>
            <a-button type="primary" ghost style="width: 40%" @click="headerAddtor">添加</a-button>
          </div>
        </div>


        <a-tag color="red" @click="tempObject={webname: '', weburl: '', index: '', position: ''}"
               style="width: 10%;margin-top: 10px;height:40px;line-height: 40px;display: flex;border-radius: 15px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);background-color: white">
          <div style="font-size: 15px;font-weight: 600;text-align: center;width: 100%">
            添加导航栏
          </div>
        </a-tag>
      </a-popover>


    </div>


    <div style="width: 100%;font-size: 19px;font-weight: 600;color: rgb(19,135,220);margin-top: 3rem;">
      <a-icon type="smile" style="font-size: 22px"/>
      底部导航栏
    </div>


    <div style="display: flex;flex-wrap: wrap;align-items: center;">
      <div v-for="(item,i) in website_info.footerLinks" :key="item.id" style="width: 10%;margin: 0 5px"
           @click="tempObject={webname: item.name, weburl: item.url, index: item.index, position: item.position}">
        <a-popover v-model="header_add_show_edit_F[i]" trigger="click" :destroyTooltipOnHide="true">
          <div slot="content">
            <div slot="content">
              <div style="margin:8px 0">
                <a-input addon-before="名称" v-model="tempObject.webname" placeholder="输入名称"/>
              </div>

              <div style="margin:8px 0">
                <a-input addon-before="地址" v-model="tempObject.weburl" placeholder="输入完整的https地址"/>
              </div>

              <div style="margin:8px 0">
                <a-input addon-before="权重" v-model="tempObject.index" placeholder="0-100"/>
              </div>

              <div style="margin:8px 0">
                <a-input addon-before="位置" v-model="tempObject.position" placeholder="0为底部 1为顶部"/>
              </div>

              <div style="margin:8px 0;display: flex;justify-content: space-between">
                <a-button type="danger" ghost style="width: 30%" @click="$set(header_add_show_edit_F,i,false)">取消
                </a-button>
                <a-button type="danger" ghost style="width: 30%" @click="deleteItemF(item.id,i)">删除</a-button>
                <a-button type="primary" ghost style="width: 30%" @click="editITemF(item.id,i)">修改</a-button>
              </div>
            </div>
          </div>
          <a-tag color="purple"
                 style="width: 100%;height:40px;margin-top: 10px;line-height: 40px;display: flex;border-radius: 15px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);background-color: white">
            <div style="font-size: 14px;font-weight: 600;text-align: center;width: 100%">
              {{ item.name }}
            </div>
          </a-tag>
        </a-popover>
      </div>


      <a-popover v-model="header_add_show_F" trigger="click" :destroyTooltipOnHide="true">
        <div slot="content">
          <div style="margin:8px 0">
            <a-input addon-before="名称" v-model="tempObject.webname" placeholder="输入名称"/>
          </div>

          <div style="margin:8px 0">
            <a-input addon-before="地址" v-model="tempObject.weburl" placeholder="输入完整的https地址"/>
          </div>

          <div style="margin:8px 0">
            <a-input addon-before="权重" v-model="tempObject.index" placeholder="0-100"/>
          </div>

          <div style="margin:8px 0">
            <a-input addon-before="位置" v-model="tempObject.position" placeholder="0为底部 1为顶部"/>
          </div>

          <div style="margin:8px 0;display: flex;justify-content: space-between">
            <a-button type="danger" ghost style="width: 40%" @click="header_add_show_F=false">取消</a-button>
            <a-button type="primary" ghost style="width: 40%" @click="headerAddtor">添加</a-button>
          </div>
        </div>


        <a-tag color="red" @click="tempObject={webname: '', weburl: '', index: '', position: ''}"
               style="width: 10%;margin-top: 10px;height:40px;line-height: 40px;display: flex;border-radius: 15px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);background-color: white">
          <div style="font-size: 15px;font-weight: 600;text-align: center;width: 100%">
            添加导航栏
          </div>
        </a-tag>
      </a-popover>


    </div>


    <a-modal :width="1000" v-model="webInfo_setting" title="网页信息编辑" @ok="changeInfo" okText="提交修改"
             cancelText="取消修改"
             @cancel="webInfo_setting = false">
      <div style="display: flex;margin-top:15px;align-items: center;justify-content: space-between">
        <div style="font-size: 20px">网站标题:</div>
        <div style="width: 80%">
          <a-input placeholder="Basic usage" v-model="webInfo.website_title"/>
        </div>
      </div>

      <div style="display: flex;margin-top:15px;align-items: center;justify-content: space-between">
        <div style="font-size: 20px">备案号:</div>
        <div style="width: 80%;">
          <a-input placeholder="Basic usage" v-model="webInfo.foot_info"/>
        </div>
      </div>

      <div style="display: flex;margin-top:15px;align-items: center;justify-content: space-between">
        <div style="font-size: 20px">是否付费:</div>
        <div style="width: 80%;">
          <a-select v-model="webInfo.paid" style="width: 100%">
            <a-select-option :value="1">开启</a-select-option>
            <a-select-option :value="0">关闭</a-select-option>
          </a-select>
        </div>
      </div>

      <div style="display: flex;margin-top:15px;align-items: center;justify-content: space-between">
        <div style="font-size: 20px">黑名单页公告:</div>
        <div style="width: 80%;">
          <a-textarea placeholder="Basic usage" v-model="webInfo.blacklist_notice" auto-size/>
        </div>
      </div>

      <div style="display: flex;margin-top:15px;align-items: center;justify-content: space-between">
        <div style="font-size: 20px">网站关键字:</div>
        <div style="width: 80%;">
          <a-input placeholder="Basic usage" v-model="webInfo.key_words"/>
        </div>
      </div>


      <div style="display: flex;margin-top:15px;align-items: center;justify-content: space-between">
        <div style="font-size: 20px">网页描述:</div>
        <div style="width: 80%;">
          <a-input placeholder="Basic usage" v-model="webInfo.description"/>
        </div>
      </div>


      <div style="display: flex;margin-top:15px;align-items: center;justify-content: space-between">
        <div style="font-size: 20px">统计代码:</div>
        <div style="width: 80%;">
          <a-input placeholder="Basic usage" v-model="webInfo.website_statistics"/>
        </div>
      </div>


      <div style="display: flex;margin-top:15px;align-items: center;justify-content: space-between">
        <div style="font-size: 20px">作图页公告:</div>
        <div style="width: 80%;">
          <a-textarea placeholder="Basic usage" v-model="webInfo.page_notice" auto-size/>
        </div>
      </div>


      <div style="display: flex;margin-top:15px;align-items: center;justify-content: space-between">
        <div style="font-size: 20px">广告图片地址:</div>
        <div style="width: 80%;">
          <a-input placeholder="Basic usage" v-model="webInfo.adv_img"/>
        </div>
      </div>


      <div style="display: flex;margin-top:15px;align-items: center;justify-content: space-between">
        <div style="font-size: 20px">广告跳转地址:</div>
        <div style="width: 80%;">
          <a-input placeholder="Basic usage" v-model="webInfo.adv_url"/>
        </div>
      </div>


    </a-modal>

  </div>
</template>

<script>

const key = "123"
export default {
  name: 'webInfo',
  data() {
    return {
      header_add_show: false,
      header_add_show_F: false,
      header_add_show_edit: [],
      header_add_show_edit_F: [],

      webInfo_setting: false,
      tempObject: {webname: '', weburl: '', index: '', position: ''},
      webInfo: {},
      website_info: {
        headerLinks: [],
        footerLinks: []
      },
      today_info: {}
    }
  },
  methods: {
    async headerAddtor() {
      await this.$axios.post('/Admin/Api/AddLinks', {
        ...this.tempObject
      }).then(value => {
        this.$message.success({content: value.data.msg, key, duration: 1})
        this.$router.go(0)
      }).catch(e => {
        this.$message.error({content: e.msg, key, duration: 1})
      })
    },


    deleteItemF(v, i) {
      this.$axios.post(`/Admin/Api/DelLinks/${v}`).then(
          value => {
            this.$message.success({content: value.data.msg, key, duration: 1})
            this.website_info.footerLinks = this.website_info.footerLinks.filter(val => val.id != v)
            this.$set(this.header_add_show_edit, i, false)
          }
      ).catch(err => {
        this.$message.error({content: err.msg, key, duration: 1})
        this.$set(this.header_add_show_edit, i, false)
      })
    },

    editITemF(v, i) {
      this.$axios.post(`/Admin/Api/ChangeLinks/${v}`, {
        ...this.tempObject
      }).then(value => {
            // eslint-disable-next-line no-unused-vars
            const temp = this.website_info.footerLinks.find(val => val.id == v)
            temp.name = this.tempObject.webname
            temp.url = this.tempObject.weburl
            temp.index = this.tempObject.index
            temp.position = this.tempObject.position

            this.$message.success({content: value.data.msg, key, duration: 1})
            this.$set(this.header_add_show_edit, i, false)
          }
      ).catch(err => {
        this.$message.error({content: err.msg, key, duration: 1})
        this.$set(this.header_add_show_edit, i, false)
      })
    },

    deleteItem(v, i) {
      this.$axios.post(`/Admin/Api/DelLinks/${v}`).then(
          value => {
            this.$message.success({content: value.data.msg, key, duration: 1})
            this.website_info.headerLinks = this.website_info.headerLinks.filter(val => val.id != v)
            this.$set(this.header_add_show_edit, i, false)
          }
      ).catch(err => {
        this.$message.error({content: err.msg, key, duration: 1})
        this.$set(this.header_add_show_edit, i, false)
      })
    },

    editITem(v, i) {
      this.$axios.post(`/Admin/Api/ChangeLinks/${v}`, {
        ...this.tempObject
      }).then(value => {
            // eslint-disable-next-line no-unused-vars
            const temp = this.website_info.headerLinks.find(val => val.id == v)
            temp.name = this.tempObject.webname
            temp.url = this.tempObject.weburl
            temp.index = this.tempObject.index
            temp.position = this.tempObject.position

            this.$message.success({content: value.data.msg, key, duration: 1})
            this.$set(this.header_add_show_edit, i, false)
          }
      ).catch(err => {
        this.$message.error({content: err.msg, key, duration: 1})
        this.$set(this.header_add_show_edit, i, false)
      })
    },

    changeInfo() {
      this.$message.loading({content: '提交中', key, duration: 0})
      this.$axios.post('/Admin/Api/ChangeWebSiteInfo', {
        ...this.webInfo
      }).then(v => {
        this.$message.success({content: v.data.msg, key, duration: 2})
        this.webInfo_setting = false
      }).catch(e => {
        this.$message.error({content: e.msg, key, duration: 2})
        this.webInfo_setting = false
      })
    }

  },
  async created() {
    this.website_info = JSON.parse((await this.$axios.get('/Admin/Api/GetWebSiteInfo')).data.data)
    this.today_info = JSON.parse((await this.$axios.post('/Admin/Api/getWebsiteStatistics')).data.data)
    this.webInfo = this.website_info.website_info[0]
    console.log(this.website_info)
  }
}
</script>

<style scoped>

</style>